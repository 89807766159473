import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { useIntl } from "gatsby-plugin-intl"

import Button from "components/Button/Button"
import InformationTitle from "components/InformationTitle/InformationTitle"

const StyledWrapper = styled.section`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  width: 100%;
  height: 100%;
  @media (max-width: 800px) {
    max-width: 80vw;
  }

  @media (max-width: 450px) {
    max-width: 95%;
  }
`

const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.home.buttonPurple};
  z-index: 100;
`

const StyledInformationTitle = styled(InformationTitle)`
  @media (min-width: 1750px) {
    font-size: ${({ theme }) => `calc(${theme.fontSize.s} + 0.2vmax)`};
  }
`

const StyledInformationItem = styled.p`
  color: ${({ theme }) => theme.colors.home.informationDescription};
  font-size: ${({ theme }) => theme.fontSize.xxxxs};
  width: 100%;
  margin-bottom: 25px;
  font-family: "Roboto";
  text-align: justify;
  z-index: 100;
  position: relative;

  @media (min-width: 600px) {
    font-size: ${({ theme }) => theme.fontSize.xxxs};
  }

  @media (min-width: 1750px) {
    font-size: ${({ theme }) => `calc(${theme.fontSize.xxxxs} + 0.15vmax)`};
  }
`

const Information = ({ title, content }) => {
  const intl = useIntl()

  return (
    <StyledWrapper>
      <StyledInformationTitle>{title}</StyledInformationTitle>
      {content.map(({ text }) => (
        <StyledInformationItem key={Math.random(64)}>
          {text}
        </StyledInformationItem>
      ))}
      <StyledButton
        name={intl.formatMessage({ id: "home.secondView.button" })}
        path="/services"
      />
    </StyledWrapper>
  )
}

Information.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      id: PropTypes.number,
    })
  ).isRequired,
}

export default Information
