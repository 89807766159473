import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import useWindowSize from "utils/useWindowSize"

import InformationTitle from "../InformationTitle/InformationTitle"
import InformationText from "../InformationText/InformationText"

gsap.registerPlugin(ScrollTrigger)

const StyledWrapper = styled.article`
  display: flex;
  margin: 0 15px;
  flex-direction: column;
  height: auto;
  width: auto;
  max-width: 350px;
  max-height: 300px;
  min-height: 210px;

  @media (max-width: 829px) {
    max-height: 200px;
    align-items: center;
    max-width: 450px;
  }

  @media (max-height: 450px) and (orientation: landscape) {
    margin-bottom: 20px;
  }

  @media (min-width: 1750px) {
    height: 235px;
  }
`

const StyledInformationText = styled(InformationText)`
  font-size: ${({ theme }) => theme.fontSize.xxxxs};
  text-align: justify;
  line-height: 13px;
  line-height: 1rem;
  color: ${({ theme }) => theme.colors.home.informationDescription};
  @media (max-width: 1200px) {
    font-size: 0.7rem;
  }

  @media (max-width: 750px) {
    line-height: 1.5rem;
  }
`

const InformationSquare = ({ title, text }) => {
  const ref = useRef(null)
  const size = useWindowSize()

  useEffect(() => {
    const elements = ref.current.children

    if (size.width > 750) {
      gsap.fromTo(
        elements,
        { y: "+=25", opacity: 0 },
        {
          y: 0,
          opacity: 1,
          duration: 0.7,
          ease: "ease",
          stagger: 0.4,
          scrollTrigger: {
            trigger: ref.current,
            start: "top 90%",
          },
        }
      )
    } else {
      gsap.set(elements, { opacity: 1 })
    }
  })

  return (
    <>
      <StyledWrapper ref={ref}>
        <InformationTitle>{title}</InformationTitle>
        <StyledInformationText>{text}</StyledInformationText>
      </StyledWrapper>
    </>
  )
}

InformationSquare.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default InformationSquare
