import styled from "styled-components"

const Square = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme, red }) =>
    red ? theme.colors.home.squareRed : theme.colors.home.squareYellow};
  background-size: cover;
  background-image: ${({ bgImg }) => `url(${bgImg})`};
`

export default Square
