import styled from "styled-components"

const Eclipse = styled.div`
  min-width: 50vw;
  min-height: 50vw;
  width: 1200px;
  height: 1200px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.eclipseGrey};
  z-index: 0;

  @media (max-width: 1500px) {
    width: 1100px;
    height: 1100px;
  }
  @media (min-width: 1500px) {
    width: 1300px;
    height: 1300px;
  }
`

export default Eclipse
