import styled from "styled-components"

const Logo = styled.h2`
    font-weight: 700;
    font-size: 72px;
    color: rgb(249, 236, 118);
    text-transform: uppercase;
    margin: 0px;
    line-height: 0.8;
    height: auto;
    color: white;
    margin-bottom: 20px;

  @media (max-width: 1200px) {
    font-size: ${({ theme }) => theme.fontSize.l};
  }

  @media (max-width: 800px) {
    max-width: 70vw;
  }

  @media (min-width: 1750px) {
    // font-size: ${({ theme }) => `calc(${theme.fontSize.xxl} + 0.5vmax)`};
  }

  @media (max-height: 450px) and (orientation: landscape) {
    max-width: 90vw;
  }

  @media (max-width: 550px) {
    // font-size: ${({ theme }) => theme.fontSize.l};
    max-width: 70vw;
  }

  @media (max-width: 350px) {
    // font-size: ${({ theme }) => theme.fontSize.m};
  }
`
export default Logo
