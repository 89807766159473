import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import Square from "../Square/Square"

import SquareOne from "images/squareone.png"
import SquareTwo from "images/squaretwo.jpg"

gsap.registerPlugin(ScrollTrigger)

const StyledWrapper = styled.div`
  width: 300px;
  height: 300px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);

  @media (min-width: 1750px) {
    width: calc(300px + 0.5vmax);
    height: calc(300px + 0.5vmax);
  }
`

const Squares = () => {
  const ref = useRef(null)

  useEffect(() => {
    gsap.fromTo(
      ref.current,
      { x: "+=20", y: "+=50" },
      {
        x: 0,
        y: 0,
        ease: "ease",
        duration: 1.2,
        scrollTrigger: {
          scrub: 1,
          trigger: ref.current,
          start: "top 80%",
        },
      }
    )
  }, [])

  return (
    <>
      <StyledWrapper>
        <Square red style={{ gridColumn: 1, gridRow: 1 }} />
        <Square style={{ gridColumn: 2, gridRow: 2 }} bgImg={SquareOne} />
        <Square style={{ gridColumn: 1, gridRow: 3 }} bgImg={SquareTwo} />
        <Square
          style={{
            gridColumn: 3,
            gridRow: 3,
            position: "relative",
          }}
          ref={ref}
        />
      </StyledWrapper>
    </>
  )
}

export default Squares
